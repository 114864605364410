import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  // constructor() { }
  private apiUrl = '/Mobileapi/microsite_catagory_list';

  private subCategoryApiUrl = '/Mobileapi/microsite_sub_catagory_list';
  private listApiUrl = '/Mobileapi/microsite_member_subcat';
  // private listApiUrl = '/Mobileapi/microsite_list_item';
  private MembertypeVoucher = '/Mobileapi/microsite_member_voucher';
  private saveSelectedItemsUrl = '/Mobileapi/save_selected_items';
  private certificate = '/Mobileapi/get_certificate_microsite';
  private statementUrl = '/Mobileapi/get_balance_statement_microsite';
  private editdataUrl = '/Mobileapi/microsite_fetch_listitem';
  private voucherdataUrl = '/Mobileapi/microsite_fetch_voucher_order';
  private removeItemUrl = '/Mobileapi/remove_listitem'



  //--------------------------------------------------Voucher Apis---------------------------
  private authApi = '/Mobileapi/giftvoucher_authorization_verify';
  private bearerTokenApi = '/Mobileapi/giftvoucher_get_bearer_token';
  // private voucherCategoryApi = '/rest/v3/catalog/categories';
  private voucherCategoryApi = '/Mobileapi/microsite_voucher_category';

  //--------------------------------------------------End------------------------------------

  constructor(private http: HttpClient) { }

  getCategories(token: string): Observable<any[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      token: token
    };

    // console.log('API URL:', this.apiUrl);
    return this.http.post<any>(this.apiUrl, body, { headers });

    // console.log(this.apiUrl);
  }


  getSubCategories(categoryId: string, token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      category_id: categoryId,
      token: token
    };

    return this.http.post(this.subCategoryApiUrl, body, { headers });
  }


  getListItems(subcategory_id: string, member_type: string, token: string, user_code: string): Observable<any> {

    const body = {
      subcategory_id: subcategory_id,
      member_type: member_type,
      token: token,
      user_code: user_code
    };
    return this.http.post(this.listApiUrl, body);
  }

  getMembertypeVoucher(subcategory_id: string, member_type: string, token: string): Observable<any> {
    const payload = {
      subcategory_id: subcategory_id,
      member_type: member_type,
      token: token
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(this.MembertypeVoucher, payload, { headers });
  }

  saveSelectedItem(user_code: string, selected_items: any[], token: string, subcategory_id: string, member_type: string): Observable<any> {
    const payload = {
      user_code: user_code,
      selected_items: selected_items,
      token: token,
      subcategory_id: subcategory_id,
    member_type: member_type
    };
    console.log(payload)
    return this.http.post<any>(this.saveSelectedItemsUrl, payload);
  }

  getCertificateUrl(userId: string, token: string): Observable<any> {
    const body = {
      architect: userId,
      token: token
    };

    return this.http.post<any>(this.certificate, body);
  }

  // downloadCertificate(user_id: string, token: string): Observable<any> {
  //   const payload = {
  //     user_id: user_id,
  //     token: token
  //   };
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Accept': 'application/pdf'
  //   });

  //   return this.http.post(this.certificate, payload, { headers, responseType: 'blob' });
  // }

  downloadStatement(user_id: string, fromDate: string, toDate: string, token: string): Observable<any> {
    const payload = {
      architect: user_id,
      from_date: fromDate,
      to_date: toDate,
      token: token
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    return this.http.post<any>(this.statementUrl, payload, { headers });
  }



  //----------------------------------Voucher Apis-------------------------------------
  // generateAuthCode() {
  //   const payloads = {
  //     "token": "609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW",
  //     "clientId": "5e0417a9a61fbebbf22efb5c2646bee1",
  //     "username": "centuryplysandbox@woohoo.in",
  //     "password": "WoohooUAT@1"
  //   };

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Accept': 'application/json'
  //   });

  //   return this.http.post(this.authApi, payloads, { headers });
  // }


  // generateBearerToken() {
  //   const authorizationCode = this.generateAuthCode();

  //       const payloads = {
  //         "token": "609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW",
  //         "clientId": "5e0417a9a61fbebbf22efb5c2646bee1",
  //         "clientSecret": "7d180a544d33745e18c10811ab975ee7",
  //         "authorizationCode": authorizationCode
  //       };

  //       console.log('Payload for bearer token request:', payloads);
  //       return this.http.post(this.bearerTokenApi, payloads);
  // }



  // getCategoryList(qValue: string): Observable<any> {
  //   return this.generateBearerToken().pipe(
  //     switchMap((bearerToken) => {
  //       const headers = new HttpHeaders().set('Authorization', `Bearer ${bearerToken}`);
  //       const params = new HttpParams().set('q', qValue);
  //       console.log('Headers and Params for category list request:', { headers, params });

  //       return this.http.get(this.voucherCategoryApi, { headers, params });
  //     })
  //   );
  // }
  // getCategoryList(qValue: string): Observable<any> {
  //   const bearer = this.generateBearerToken();
  //   console.log(bearer)
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${bearer}`);
  //   const params = new HttpParams().set('q', '1');
  //   // console.log(params);

  //   return this.http.get(this.voucherCategoryApi, { headers, params });
  // }


  getCategoryList(): Observable<any> {
    return this.http.get(this.voucherCategoryApi);
  }





  //-------------------------Data Edit--------------------------------------------------------

  getEditedData(categoryId: any, user_code: any, token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      category_id: categoryId,
      user_code: user_code,
      token: token
    };

    // console.log(body)

    return this.http.post(this.editdataUrl, body, { headers });
  }
  getVoucherData(user_code: any, token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      user_code: user_code,
      token: token
    };

    console.log(body)

    return this.http.post(this.voucherdataUrl, body, { headers });
  }



  removeItem(user_code: string, listitem_id: string, token: string): Observable<any> {
    const body = { user_code, listitem_id, token };
    return this.http.post(this.removeItemUrl, body);
  }

}