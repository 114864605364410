import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../category.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';


@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.css']
})
export class WebinarsComponent {

  showTable = false;
  showVoucherTable = false;

  tableData: any[] = [];
  voucherData: any[] = [];
  profileData: any;

  selectedCategoryName: string = ''; 
  selectedCards: any[] = []; 

  categories: any[] = [];
  private token: any = '609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW';
  listiem_id: any;
  selectedIndex: any;

  constructor(
    private categoryService: CategoryService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getCat();
    this.profileData = localStorage.getItem('profileData');
  }

  //---------------------Category--------------------------------
  getCat() {
    this.categoryService.getCategories(this.token).subscribe(
      (response: any) => {
        // console.log(response);
        this.categories = Object.values(response.response.data);
        // console.log(this.categories);
      },
      (error) => {
        console.error('Error fetching categories', error);
      }
    );
  }
  //-------------------------------------------------------------

  // Handle Category Click
  onCategoryClick(categoryId: number): void {
    const selectedCategory = this.categories.find((category: { id: number; }) => category.id === categoryId);
    //console.log('Selected Category:', selectedCategory);
    this.selectedIndex = selectedCategory.id;
    console.log(this.selectedIndex);

    if (!selectedCategory) {
      // this.selectedCategoryName = selectedCategory.category_name;
      // this.showTable = true;
        // console.error('Category not found');
        alert('Category not found');
        return; 
    }

    const params = {
        category_id: selectedCategory.id,
        user_code: JSON.parse(this.profileData).user_code,
        token: this.token
    };

    

    if (selectedCategory.table_status === "active") {
        // console.log('Fetching edited data...');
        this.fetchEditedData(params.category_id, params.user_code);
        // alert('Fetching voucher items...');
    } else {
        // console.log('Fetching voucher items...');
        this.fetchVoucherItems(params.user_code);
        // alert('Fetching voucher items...');
    }
  }

  // Fetch Edited Data
  fetchEditedData(categoryId: number, user_code: string): void {
    this.categoryService.getEditedData(categoryId, user_code, this.token).subscribe(
      (response: any) => {
        this.tableData = response.response.data;
        this.showTable = true;
        this.showVoucherTable = false;
      },
      (error) => {
        alert('Error fetching the data');
        // console.error('Error fetching edited data', error);
      }
    );
  }
  
  // Voucher Data
  // fetchVoucherItems(user_code?: any): void {
  //   if (this.profileData) {
  //     const user_code = JSON.parse(this.profileData).user_code;
  //     this.categoryService.getVoucherData(user_code, this.token).subscribe(
  //       (response: any) => {
  //         this.voucherData = response.response.data.map((product: { product_details: string; response_data:any; }) => {
  //           return {
  //             ...product,
  //             product_details: JSON.parse(product.product_details),
  //             response_data: JSON.parse(product.response_data)
  //           };
  //         });;
  //         console.log(this.voucherData);
  //         this.showTable = false;
  //         this.showVoucherTable = true;
  //       },
  //       (error) => {
  //         // console.error('Error fetching voucher items', error);
  //         alert('Error fetching voucher items');
  //       }
  //     );
  //     } else {
  //     // console.error('Profile data is missing');
  //     alert('Profile data is missing');
  //   }
  // }


  fetchVoucherItems(user_code?: any): void {
    if (this.profileData) {
        const user_code = JSON.parse(this.profileData).user_code;
        this.categoryService.getVoucherData(user_code, this.token).subscribe(
            (response: any) => {
                // console.log('Full response data:', response);

                this.voucherData = response.response.data
                    .filter((product: { response_data: any }) => {
                        const responseData = product.response_data;

                        if (!responseData) {
                            console.error('Missing response_data for product:', product);
                            return false;
                        }

                        // parse response_data safely
                        let parsedResponseData;
                        try {
                            parsedResponseData = JSON.parse(responseData);
                        } catch (e) {
                            console.error('Error parsing response_data:', responseData, e);
                            return false; // Skip this product on parse error
                        }

                        const statusData = parsedResponseData?.data?.status || parsedResponseData?.status;

                        const normalizedStatus = typeof statusData === 'string' ? statusData.trim() : undefined;

                        // checking for valid statuses
                        const isValidStatus = normalizedStatus === 'COMPLETE' || normalizedStatus === 'PROCESSING';
                        const isNotError = parsedResponseData?.status !== 0;

                        return (isValidStatus || isNotError);
                    })
                    .map((product: { product_details: string; response_data: any }) => {
                        let parsedResponseData;
                        try {
                            parsedResponseData = JSON.parse(product.response_data); // Parse response_data here
                        } catch (e) {
                            console.error('Error parsing product response_data:', product.response_data, e);
                            return null; // Return null if parsing fails
                        }
                        // console.log('Parsed response_data:', parsedResponseData);

                        // before ordering checking orderId
                        const orderId = parsedResponseData?.data?.orderId || parsedResponseData?.orderId; // checking both paths
                        if (!orderId) {
                            console.error('No orderId found in parsed response_data:', parsedResponseData);
                            return null; // return null if orderId is missing
                        }
 
                        return {
                            ...product,
                            product_details: JSON.parse(product.product_details),
                            response_data: parsedResponseData
                        };
                    })
                    .filter((item: null) => item !== null);

                // console.log('Filtered voucherData:', this.voucherData);
                
                // binding
                this.voucherData = this.voucherData.reduce((acc, item) => {
                    const orderId = item.response_data?.data?.orderId || item.response_data?.orderId; 
                    const status = item.response_data?.data?.status || item.response_data?.status;

                    // Safely check if cards exists in both locations
                    const getCards = (responseData: any) => {
                        // Check for cards in response_data directly or within data
                        return responseData.data?.cards || responseData.cards || [];
                    };

                    const productNames = getCards(item.response_data).map((card: { productName: any; }) => card.productName);

                    // Valid before adding to accumulator
                    if (orderId && productNames.length > 0 && status !== 0) {
                        const existingOrder = acc.find((o: { orderId: any; }) => o.orderId === orderId);
                        if (existingOrder) {
                            existingOrder.productNames.push(...productNames);
                        } else {
                            acc.push({
                                orderId,
                                productNames: productNames,
                                status,
                                response_data: item.response_data 
                            });
                        }
                    }
                    return acc;
                }, []);
                
                // comma-separated wala
                this.voucherData = this.voucherData.map(item => ({
                    ...item,
                    productNames: Array.from(new Set(item.productNames)).join(', ')
                }));

                // console.log('Final voucherData:', this.voucherData);
                this.showTable = false;
                this.showVoucherTable = true;
            },
            (error) => {
                alert('Error fetching voucher items');
                console.error('Error fetching voucher items:', error);
            }
        );
    } else {
        alert('Profile data is missing');
    }
  }


  
  viewDetails(item: any): void {
    console.log('Selected item for details:', item); // Confirm the structure

    // Check both possible card structures
    const cards = item.response_data?.data?.cards || item.response_data?.cards || [];

    if (cards.length > 0) {
        this.selectedCards = cards.map((card: { cardNumber: any; cardPin: any; amount: any, validity: any }) => ({
            cardNumber: card.cardNumber,
            cardPin: card.cardPin,
            amount: card.amount,
            validity: card.validity,
        }));
        console.log('Selected cards:', this.selectedCards);
    } else {
        console.error('No cards found in selected item.');
    }
  }






  closeDetails(): void {
    this.selectedCards = []; 
  }
  
  
  
  

  // Remove Items
  // removeItem() {
  //   const user_code = JSON.parse(this.profileData).user_code;
  //   this.categoryService.removeItem(this.listitem_id, user_code, this.token).subscribe(
  //     (response: any) => {
  //       this.tableData = response.response.data;
  //       // Optionally, you can remove the item from the local tableData if not already handled
  //       this.tableData = this.tableData.filter(data => data.listitem_id !== item.listitem_id);
  //     },
  //     (error) => {
  //       console.error('Error removing item', error);
  //     }
  //   );
  // }

  deleteItem(listitem_id: string, token: string): void {
    const user_code = JSON.parse(this.profileData).user_code;
    this.categoryService.removeItem(user_code, listitem_id, this.token).pipe(
      catchError(error => {
        // console.error('Error deleting item', error);
        alert('Error deleting item');
        return of(null);
      })
    ).subscribe(response => {
      if (response) {
        // console.log('Item deleted successfully');
        alert('Item deleted successfully');
        this.tableData = this.tableData.filter(item => item.listitem_id !== listitem_id);
      }
    });
  }




}
