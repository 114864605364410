<main>

    <div class="tp-team-details-area pt-10 pb-30">
        <div class="container">
            <div class="tp-team-details-bg p-relative">
                <div class="tp-team-details-bg-shape d-none d-md-block">
                    <img src="assets/images/team-bg-shape.png" alt="">
                </div>
                <div class="row align-items-center">
                    <div class="col-xl-5 col-lg-5">
                        <div class="tp-team-details-thumb text-center text-md-start p-relative">
                            <div class="team-image" [ngClass]="getTeamImageClass()">
                                <!-- Use color copper, bronze, silver, diamond, royal, gold for change the background color -->
                                <!-- Ex. class="team-image copper mb-20" class="team-image bronze mb-20" -->
                                <img [src]="profileImageUrl" alt="Profile Image Url">
                            </div>
                            <div class="tp-team-details-personal-info">
                                <a class="tp-btn-grey tp-btn-edit-profile no-underline" routerLink="/profile">View
                                    Profile</a><br>
                                <a (click)="logout()" href="javascript:void(0);"
                                    class="tp-btn-grey tp-btn-edit-profile logout-profile no-underline mt-2">Logout</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-7">
                        <div class="tp-team-details-info-box">
                            <div class="tp-team-details-title-box">
                                <div class="d-md-flex">
                                    <h4 class="tp-team-details-title">{{ profile.name }}</h4>
                                    <!-- {{ profile.name }} -->
                                    <!-- <span>(Frontend-developer / Web Designer)</span> -->
                                </div>

                                <p><b>Category :</b> {{profile.category}} </p>
                                <p><b>Years with Century Joy :</b> {{ profile.experience.years }} years, {{
                                    profile.experience.months }} months, {{ profile.experience.days }} days</p>
                            </div>
                            <div class="tp-team-details-personal-info-wrap">
                                <div class="tp-team-details-personal-info">
                                    <!-- <p><b>Years with Century Joy:</b> {{ profile.experience.years }} years, {{ profile.experience.months }} months, {{ profile.experience.days }} days</p> -->
                                    <!-- <span><b>Years with Century Joy:</b> {{ profile.experience.years }} years, {{ profile.experience.months }} months, {{ profile.experience.days }} days</span> -->
                                </div>

                            </div>

                            <div class="tp-team-details-personal-info row p-0">
                                <div class="col-md-6 col-lg-6">
                                    <a class="tp-btn mb-2 w-100 text-center" data-toggle="tab" href="#home">Club
                                        Membership</a>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <!--a class="tp-btn mb-2 w-100 text-center" data-toggle="tab" href="#profile">AI Profile Builder</a-->
                                    <a class="tp-btn mb-2 w-100 text-center" href="https://aidprofile.centuryply.com/"
                                        target="_blank">AI Profile Builder</a>


                                </div>

                                <div class="col-md-6 col-lg-6" *ngFor="let voucherCategory of voucherCategories">
                                    <a class="tp-btn mb-2 w-100 text-center"
                                        (click)="redirectToVoucherPage(voucherCategory.id)">Joy Rewards</a>
                                </div>


                                <!-- <div class="col-md-6 col-lg-6">
                                    <a class="tp-btn mb-2 w-100 text-center" href="#">Joy Rewards</a>
                                </div> -->

                                <!--a class="tp-btn col-md-3 mb-2" href="https://google.com" target="__blank">AI Porfile Builder</a-->

                            </div>

                            <div class="tab-content">
                                <div id="home" class="tab-pane">

                                    <!--div class="container mt-4"-->
                                    <div class="row">

                                        <div class="col-md-6">
                                            <a (click)="downloadCertificate()" href="javascript:void(0);"
                                                class="btn btn-link ps-0">Download Certificate <i
                                                    class="fa fa-download ms-2"></i></a>
                                        </div>


                                        <!-- <div class="col-md-6">
                                            <a (click)="downloadCertificate()" href="javascript:void(0);"
                                                class="btn btn-link ps-0">Download Certificate <i
                                                    class="fa fa-download ms-2"></i></a>
                                        </div> -->


                                        <div class="col-md-6">
                                            <a data-toggle="tab" href="#statement" class="btn btn-link ps-0">Joy
                                                Statement</a>
                                        </div>

                                        <div class="tab-content">
                                            <div id="statement" class="tab-pane">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label for="fromDate" class="form-label">From Date:</label>
                                                        <input type="date" id="fromDate" [(ngModel)]="fromDate"
                                                            class="form-control" required>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="toDate" class="form-label">To Date:</label>
                                                        <input type="date" id="toDate" [(ngModel)]="toDate"
                                                            class="form-control" required>
                                                    </div>
                                                    <div class="col-md-6 d-flex align-items-center">
                                                        <a (click)="downloadStatement()" href="javascript:void(0);"
                                                            class="btn btn-link ps-0 ms-0">Download <i
                                                                class="fa fa-download ms-2"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <!-- <div class="col-md-6">

                                            <a data-toggle="tab" href="#statement" class="btn btn-link ps-0">Download
                                                Statement</a>
                                        </div>

                                        <div class="tab-content">
                                            <div id="statement" class="tab-pane">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label for="fromDate" class="form-label">From Date:</label>
                                                        <input type="date" id="fromDate" [(ngModel)]="fromDate"
                                                            class="form-control" required>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label for="toDate" class="form-label">To Date:</label>
                                                        <input type="date" id="toDate" [(ngModel)]="toDate"
                                                            class="form-control" required>
                                                    </div>
                                                    <div class="col-md-4 d-flex align-items-center">

                                                        <a (click)="downloadStatement()" href="javascript:void(0);"
                                                            class="btn btn-link ms-2"><i class="fa fa-download"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                                        <!--div class="col-md-4 d-flex align-items-center">
                                                
                                                <a (click)="downloadStatement()" href="javascript:void(0);" class="btn btn-link ms-2">Download Statement <i class="fa fa-download"></i></a>
                                            </div-->
                                        <!--div class="col-md-12 mt-3">
                                                
                                                <button (click)="downloadStatement()" class="btn btn-primary">Download Statement</button>
                                            </div-->
                                    </div>
                                    <!--/div-->


                                    <!-- <div class="row">
                                        <div class="col-md-6">
                                            <a (click)="downloadCertificate()" href="javascript:void(0);">Download Certificate</a>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="fromDate">From Date:</label>
                                            <input type="date" id="fromDate" [(ngModel)]="fromDate" class="form-control" required>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="toDate">To Date:</label>
                                            <input type="date" id="toDate" [(ngModel)]="toDate" class="form-control" required>
                                        </div>
                                        <div class="col-md-12 mt-3">
                                            <button (click)="downloadStatement()" class="tp-btn">Download Statement</button>
                                        </div>
                                    </div> -->


                                    <!--div class="tp-about-progress p-relative">
                                        <span class="progress-label">Current Status</span>
                                        <span class="progress-count">92%</span>
                                        <div class="progress">
                                            <div class="progress-bar wow slideInLeft" data-wow-duration="1s"
                                                data-wow-delay=".3s" role="progressbar" data-width="92%"
                                                aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                                                style="width: 92%; visibility: visible; animation-duration: 1s; animation-delay: 0.3s; animation-name: slideInLeft;">
                                            </div>
                                        </div>
                                    </div-->

                                    <!-- <div class="progress-bar-container mt-5 mb-4">
                                        <div class="progress-bar">
                                            <ul class="progress">
                                                <li class="active">
                                                    <span>Copper</span>
                                                </li>
                                                <li class="active">
                                                    <span>Bronze</span>
                                                </li>
                                                <li class="active">
                                                    <span>Silver</span>
                                                </li>
                                                <li class="selected ">
                                                    <span>Gold</span>
                                                </li>
                                                <li class="">
                                                    <span>Diamond</span>
                                                </li>
                                                <li class="">
                                                    <span>Royal</span>
                                                </li>
                                                
                                            </ul>
                                            <div class="bar">
                                                <div class="fill" style="width: 70%;"></div>
                                            </div>
                                        </div>
                                    </div> -->

                                    <!--div class="bar-icon" style="width: 70%;"></div-->


                                    <!-- index.component.html -->
                                    <div class="progress-bar-container mt-2 mb-2">
                                        <label for="fromDate" class="form-label">Current Status (2024-25)</label>
                                        <div class="progress-bar">
                                            <ul class="progress">
                                                <li *ngFor="let step of steps; let i = index"
                                                    [class.active]="activeSteps.includes(step)"
                                                    [class.selected]="step === memberType">
                                                    <span>{{ step }}</span>
                                                </li>
                                            </ul>
                                            <div class="bar">
                                                <div class="fill" [style.width]="progressPercentage + '%'"></div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <!--div id="profile" class="tab-pane">
                                    <div class="row">

                                        <div class="col-md-6">
                                            <a [href]="ai_profile_url ? ai_profile_url : 'javascript:void(0);'"
                                                [attr.aria-disabled]="!ai_profile_url ? true : null"
                                                class="btn btn-link" [ngClass]="{'disabled': !ai_profile_url}">View
                                                <i class="fa fa-eye ms-2"></i>
                                            </a>
                                            <a href="https://aidprofile.centuryply.com/" target="_blank"
                                                class="btn btn-link">Edit
                                                <i class="fa fa-edit ms-2"></i>
                                            </a>
                                        </div>


                                    </div>
                                </div-->
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div id="feature-one-page" class="tp-feature-area pt-50 pb-50 p-relative z-index grey-bg-2">
        <div class="tp-feature-shape-1 d-none d-xxl-block">
            <img src="assets/images/ab-shape-2.png" alt="">
        </div>
        <div class="tp-feature-shape-2">
            <img src="assets/images/ab-bg.png" alt="">
        </div>
        <div class="container">
            <div class="row row-cols-xl-5 row-cols-lg-3 justify-content-center">
                <div *ngFor="let category of categories" class="col col-sm-6 wow tpfadeUp" data-wow-duration=".9s"
                    data-wow-delay=".3s"
                    style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.3s; animation-name: tpfadeUp;">
                    <div (click)="navigateToSubcategories(category.id)" class="tp-feature-item mb-30 text-center">
                        <div class="tp-feature-icon">
                            <i [class]="category.category_icon"></i>
                        </div>
                        <div class="tp-feature-content">
                            <h4 class="tp-feature-title-sm">{{ category.category_name }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</main>