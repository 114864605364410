import { Component, OnInit } from '@angular/core';
import { AuthManagerService } from '../auth-manager.service';
import { ActivatedRoute,Router } from '@angular/router';
import { CategoryService } from '../category.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})



export class IndexComponent {

  profile: any = {};
  categories: any;

  categoryId: any;
  voucherCategories: any[] = [];

  private userCode: string = "";
  private user_id: string = "";
  public fromDate: string = '';
  public toDate: string = '';
  private architect: string = '';
  private token: string = '609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW';
  category_index: any = 1;
  profileData: any;

  profileImageUrl: string = 'assets/images/blank-profile-picture640.png';
  ai_profile_url: any;

  memberType: any;
  progressPercentage: number = 0;
  steps: string[] = ['Copper', 'Bronze', 'Silver', 'Gold', 'Diamond', 'Royal'];
  activeSteps: string[] = [];


  constructor(private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private authManager: AuthManagerService) { }

  ngOnInit(): void {
    this.profileData = localStorage.getItem('profileData');

    if (this.profileData) {
      // Parse profileData once and use it
      this.profile = JSON.parse(this.profileData);

      // Set profile properties
      this.profile.experience = this.calculateExperience(this.profile.year_of_exp);
      this.profileImageUrl = this.profile.profile_image_url || 'assets/images/blank-profile-picture640.png';
      this.ai_profile_url = this.profile.ai_profile_url || '';

      this.memberType = this.profile.category;

      this.calculateProgress();
      this.getCat();
    } else {
      // console.error('No profile data found in local storage');
      this.router.navigate(['/otp']);
    }

    // fetch categories
    this.fetchVoucherCategories();
    this.route.queryParams.subscribe(params => {
      this.categoryId = +params['categoryId'];
    });
  }


  // ngOnInit(): void {
  //   this.profileData = localStorage.getItem('profileData');
  //   const profileData = localStorage.getItem('profileData'); 
  //   if (profileData) {
  //     this.profile = JSON.parse(profileData);
  //     this.profile.experience = this.calculateExperience(this.profile.year_of_exp);
  //     // console.log('Profile Data:', this.profile); // Log the profile data to check
  //     } else {
  //       console.error('No profile data found in local storage');
  //       this.router.navigate(['/otp']); 
  //   }

  //   this.profileImageUrl = JSON.parse(this.profileData).profile_image_url ? JSON.parse(this.profileData).profile_image_url : 'assets/images/blank-profile-picture640.png';
  //   console.log(this.profileImageUrl);
  //   this.memberType = JSON.parse(this.profileData).category;
  //   this.calculateProgress();
  //   this.getCat();
  // }


  fetchVoucherCategories(): void {
    this.categoryService.getCategoryList().subscribe(response => {
      this.voucherCategories = response.response.data;
    });
  }

  redirectToVoucherPage(categoryId: number): void {
    this.router.navigate(['/giftvoucher'], { queryParams: { categoryId } });
  }


  getTeamImageClass(): string {
    return this.memberType ? this.memberType + ' mb-20' : 'default mb-20';
  }

  calculateProgress() {

    const index = this.steps.indexOf(this.memberType);

    if (index !== -1) {
      // Determine progress percentage based on member type
      this.progressPercentage = ((index + 1) / this.steps.length) * 100;

      // Set active steps
      this.activeSteps = this.steps.slice(0, index + 1);
    } else {
      this.progressPercentage = 0;
      this.activeSteps = [];
    }
  }

  //----------------------------------------------------------------------------------category_fetch
  getCat() {
    this.categoryService.getCategories(this.token).subscribe(
      (response: any) => {
        this.categories = Object.values(response.response.data);
        // console.log(this.categories);
      },
      (error) => {
        // console.error('Error fetching categories', error);
      }
    );
  }


  //--------------------------------------------------------------------------------------sub_category_navigation
  navigateToSubcategories(categoryId: string): void {
    this.router.navigate(['/category', categoryId]);
  }

  downloadCertificate(): void {
    // Check if profileData exists
    if (this.profileData) {
      const userId = JSON.parse(this.profileData).user_id;

      this.categoryService.getCertificateUrl(userId, this.token).subscribe(
        (response: any) => {
          // console.log('Download URL:', response.response.data.cloudepathurl);

          const downloadUrl = response.response?.data?.cloudepathurl;

          if (downloadUrl) {
            // Open the download URL in a new tab
            window.open(downloadUrl, '_blank');
          } else {
            alert("Download URL is not available in the response");
            // console.error('Download URL is not available in the response');
          }
        },
        (error) => {
          alert("Error fetching certificate URL");
          // console.error('Error fetching certificate URL', error);
        }
      );
    } else {
      alert("Profile data not found");
      // console.error('Profile data not found');
    }
  }

  // downloadCertificate(): void {
  //   const userId = JSON.parse(this.profileData).user_id;

  //   this.categoryService.getCertificateUrl(userId, this.token).subscribe(
  //     (response: any) => {
  //       console.log('Download URL:', response.response.data.cloudepathurl);

  //       const downloadUrl = response.response?.data?.cloudepathurl;

  //       if (downloadUrl) {

  //         const a = document.createElement('a');
  //         a.href = downloadUrl;
  //         a.download = 'certificate.pdf'; // Set the file name for the download
  //         document.body.appendChild(a);
  //         a.click();
  //         document.body.removeChild(a);
  //       } else {
  //         console.error('Download URL is not available in the response');
  //       }
  //     },
  //     (error) => {
  //       console.error('Error fetching certificate URL', error);
  //     }
  //   );
  // }


  downloadStatement(): void {
    if (this.fromDate && this.toDate) {
      const userId = JSON.parse(this.profileData).user_id;

      this.categoryService.downloadStatement(userId, this.fromDate, this.toDate, this.token)
        .subscribe(response => {
          // console.log('Statement URL:', response.response.data.cloudepathurl);
          const downloadUrl = response.response.data.cloudepathurl;

          if (downloadUrl) {
            // Open the URL in a new tab
            window.open(downloadUrl, '_blank');
          } else {
            alert('Download URL is not available in the response');
            // console.error('Download URL is not available in the response');
          }
        }, error => {
          alert('Error downloading statement')
          // console.error('Error downloading statement', error);
        });
    } else {
      alert('Please select both From and To dates.');
    }
  }

  // downloadStatement(): void {
  //   if (this.fromDate && this.toDate) {
  //     const userId = JSON.parse(this.profileData).user_id;
  //     this.categoryService.downloadStatement(userId, this.fromDate, this.toDate, this.token)
  //       .subscribe(response => {
  //         console.log('Statement URL:', response.response.data.cloudepathurl);
  //         const downloadUrl = response.response.data.cloudepathurl;

  //         if (downloadUrl) {
  //           const a = document.createElement('a');
  //           a.href = downloadUrl;
  //           a.download = 'balance_statement.pdf';
  //           document.body.appendChild(a);
  //           a.click();
  //           document.body.removeChild(a);
  //         } else {
  //           console.error('Download URL is not available in the response');
  //         }
  //       }, error => {
  //         console.error('Error downloading statement', error);
  //       });
  //   } else {
  //     alert('Please select both From and To dates.');
  //   }
  // }

  calculateExperience(dateString: string): { years: number, months: number, days: number } {
    const startDate = new Date(dateString);
    const currentDate = new Date();

    let years = currentDate.getFullYear() - startDate.getFullYear();
    let months = currentDate.getMonth() - startDate.getMonth();
    let days = currentDate.getDate() - startDate.getDate();

    if (days < 0) {
      months--;
      const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      days += lastMonth.getDate(); // Number of days in the previous month
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    return {
      years,
      months,
      days
    };
  }


  logout(): void {
    this.authManager.logout();
  }

}
