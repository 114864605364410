import { Component, OnInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthManagerService } from '../auth-manager.service'; 
import { CommonModule,ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-otpform',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './otpform.component.html',
  styleUrls: ['./otpform.component.css']
})
export class OtpformComponent implements OnInit {
  otpForm: FormGroup;
  phoneForm: FormGroup;
  mobile_no: string = "";
  showOtpForm: boolean = false;

  authorizationCode: any;

  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef>;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authManager: AuthManagerService,
    private scroller: ViewportScroller
  ) {
    this.phoneForm = this.fb.group({
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
    });

    this.otpForm = this.fb.group({
      otp0: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
      otp1: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
      otp2: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
      otp3: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
      // otp4: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
      // otp5: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
    });
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.mobile_no = params['mobile'];
      if (this.mobile_no) {
        this.showOtpForm = true;
        this.authManager.checkToken(this.mobile_no);
      }
    });
    
  }

  onSubmitPhone(): void {
    if (this.phoneForm.valid) {
      this.mobile_no = this.phoneForm.value.mobile;
      this.router.navigate([], { queryParams: { mobile: this.mobile_no } });
    } else {
      alert('Phone number is invalid');
      // console.error('Phone number is invalid');
    }
  }

  goDown1() {
    this.scroller.scrollToAnchor("phoneNumber");
  }

  onSubmitOtp(): void {
    if (this.otpForm.valid) {
      const otp = `${this.otpForm.value.otp0}${this.otpForm.value.otp1}${this.otpForm.value.otp2}${this.otpForm.value.otp3}`;
      // ${this.otpForm.value.otp4}${this.otpForm.value.otp5}
      this.authManager.handleOtpSubmission(this.mobile_no, otp);
    } else {
      alert('OTP is invalid');
      // console.error('Otp is invalid');
    }
  }

  moveFocus(event: any, nextInput: any, prevInput: any) {
    const length = event.target.value.length;
    const maxLength = event.target.maxLength;
    if (length === maxLength) {
      nextInput.focus();
    } else if (length === 0 && prevInput) {
      prevInput.focus();
    }
  }

  resendOtp(): void {
    this.authManager.checkToken(this.mobile_no);
  }
}
