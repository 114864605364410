import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CategoryService } from '../category.service';
import { AuthManagerService } from '../auth-manager.service';
import { ViewportScroller } from '@angular/common';

declare var window: any;


@Component({
  selector: 'app-skilldevelopment',
  templateUrl: './skilldevelopment.component.html',
  styleUrls: ['./skilldevelopment.component.css']
})
export class SkilldevelopmentComponent implements OnInit {

  private whatsappMessageEndpoint = '/Mobileapi/send_whatsapp_otp';

  categories: any[] = [];
  private userCode: string = '';
  subcategories: any[] = [];
  private token: string = '609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW';
  activeSubcategory: any = null;
  listItems: any[] = [];
  vouchers: any[] = [];
  profileData: any;
  categoryName: any;
  successMessage: string = '';
  errorMessage: string = '';
  selectedItemsArray: string[] = [];
  subcategory_id : any;
  showCourseSection : boolean = false;
  activeSubcategoryDiv: any = null;

  bearerToken: any;
  voucherCategories: any;
  selectedCategoryId: number | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private http: HttpClient,
    private authManager: AuthManagerService,
    private scroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.getCat();

    // Fetch subcategories
    this.profileData = localStorage.getItem('profileData');
    const categoryId = this.route.snapshot.paramMap.get('categoryId');
    if (categoryId) {
      this.categoryService.getSubCategories(categoryId, this.token).subscribe(
        (data) => {
          this.subcategories = Object.values(data.response.data);
          this.categoryName = this.subcategories[0].category_name;
          if (this.subcategories.length > 0) {
            this.setActiveSubcategory(this.subcategories[0]);
          }
        },
        (error) => {
          // console.error('Error fetching subcategories', error);
        }
      );
    }

  }

  getCat() {
    this.categoryService.getCategories(this.token).subscribe(
      (response: any) => {
        this.categories = Object.values(response.response.data);
      },
      (error) => {
        // console.error('Error fetching categories', error);
      }
    );
  }

  setActiveSubcategory(subcategory: any): void {
    this.activeSubcategory = subcategory;
    setTimeout(() => {this.scroller.scrollToAnchor("skillDevelopmentDetails")},500);

    if (subcategory.service_content === 'active') {
      this.fetchListItems(subcategory.id, JSON.parse(this.profileData).category);
    } else {
      this.fetchVoucherItems(subcategory.id, JSON.parse(this.profileData).category);
    }
  }

  fetchListItems(subcategory_id: string, member_type: string): void {
    const user_code = JSON.parse(this.profileData).user_code;
    this.categoryService.getListItems(subcategory_id, member_type, this.token, user_code).subscribe(
      (response) => {
        this.listItems = Object.values(response.response.data);
        this.subcategory_id = this.listItems[0].subcategory_id;
      },
      (error) => {
        // console.error('Error fetching list items', error);
      }
    );
  }

  // fetchVoucherItems(id?: any, category?: any): void {

  //   const bearerToken = localStorage.getItem('bearerToken');
  
  // if (!bearerToken) {
  //   this.authManager.handleTokenRejected();
  //   return;
  // }

  //   this.categoryService.getCategoryList().subscribe(
  //     (categoryListResponse) => {
  //       this.voucherCategories = categoryListResponse.response.data;
  //       // console.log('Category List Response:', this.voucherCategories);
  //     },
  //     (error) => {
  //       // console.error('Error fetching category list:', error);
  //     }
  //   );
  // }

  async fetchVoucherItems(id?: any, category?: any): Promise<void> {
    try {
        // const bearerToken = await this.authManager.ensureBearerToken();

        this.categoryService.getCategoryList().subscribe(
            (categoryListResponse) => {
                this.voucherCategories = categoryListResponse.response.data;
            },
            (error) => {
                console.error('Error fetching category list:', error);
            }
        );
    } catch (error) {
        console.error('Error ensuring bearer token:', error);
    }
}

  shouldShowCourseSection(value: boolean ): void {
    //return this.activeSubcategory && this.activeSubcategory.service_content === 'active';
    this.showCourseSection = value;
    if(value){
      this.activeSubcategoryDiv = this.activeSubcategory;
    }
  }

  shouldShowVoucherSection(): boolean {
    return this.activeSubcategory && this.activeSubcategory.service_content !== 'active';
  }

  redirectToGiftVoucher(voucherCategoryId: number): void {
    this.router.navigate(['/giftvoucher'], { queryParams: { categoryId: voucherCategoryId } });
  }
  
  saveSelectedItems(): void {

    const checkboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[name="item"]:checked');
    // const selectedItemsArray: string[] = [];

    checkboxes.forEach((checkbox: HTMLInputElement) => {
        this.selectedItemsArray.push(checkbox.value); 
    });

    // console.log(selectedItemsArray);


    if (this.selectedItemsArray.length === 0) {
      this.errorMessage = "Please select at least one item before saving.";
      this.showErrorMessage();
      return;
    }
    const user_code = JSON.parse(this.profileData).user_code;
    const member_type = JSON.parse(this.profileData).category;
    this.categoryService.saveSelectedItem(user_code, this.selectedItemsArray, this.token, this.subcategory_id,member_type).subscribe(
      response => {
        // console.log("That's a great Choice ! You will receive your reward within 7 working days", response);

        // Handling based on status code and message
      if (response.response.status === 1) {
        this.successMessage = "That's a great Choice ! You will receive your reward within 7 working days.";
        this.errorMessage = ''; // Clear any previous error message
        this.showSuccessMessage();
      } else if (response.response.status === 0 && response.response.msg === 'Selection exceeds the allowed limit.') {
        this.errorMessage = 'You have exceeded the allowed number of selections. Please deselect some items and try again.';
        this.successMessage = '';
        this.showErrorMessage();
      } else {
        this.errorMessage = response.response.msg || 'Error saving selected items. Please try again.';
        this.successMessage = '';
        this.showErrorMessage();
      }
        this.listItems.forEach(item => {
          if (item.selected) {
            item.disabled = true;
            item.selected = false;
          }
        });

        setTimeout(() => {
          this.successMessage = '';
        }, 10000);
      },
      error => {
        // console.error('Error saving selected items', error);
        this.errorMessage = 'Error saving selected items. Please try again.';
        this.showErrorMessage();
        setTimeout(() => {
          this.errorMessage = '';
        }, 3000);
      }
    );
  }

  showErrorMessage(): void {
    const errorModalElement = document.getElementById('errorModal');
    if (errorModalElement) {
      const errorModal = new window.bootstrap.Modal(errorModalElement); 
      errorModal.show(); 
      
      setTimeout(() => {
          errorModal.hide(); 
      }, 3000);
  }
  }

  showSuccessMessage(): void {
    const successModalElement = document.getElementById('successModal');
    if (successModalElement) {
      const successModal = new window.bootstrap.Modal(successModalElement);
      successModal.show();
      
      setTimeout(() => {
          successModal.hide();
      }, 3000);
  }
  }
  

  // showErrorMessage(): void {
  //   const errorModal = new bootstrap.Modal(document.getElementById('errorModal')!);
  //   errorModal.show();
  // }
  
  // showSuccessMessage(): void {
  //   const successModal = new bootstrap.Modal(document.getElementById('successModal')!);
  //   successModal.show();
  // }


  
  
}
