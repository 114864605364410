import { Component } from '@angular/core';

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.css']
})
export class VouchersComponent {

}






// <!-- Courses -->
// <div id="feature-one-page" class="tp-feature-area pt-130 pb-110 p-relative z-index grey-bg-2"
//   *ngIf="activeSubcategory && activeSubcategory.service_content === 'active'">
//   <div class="tp-feature-shape-1 d-none d-xxl-block">
//     <img src="assets/images/ab-shape-2.png" alt="">
//   </div>
//   <div class="tp-feature-shape-2">
//     <img src="assets/images/ab-bg.png" alt="">
//   </div>
//   <div class="container">

//     <div class="row row-cols-xl-4 row-cols-lg-2 justify-content-center">
//       <div *ngFor="let subcategory of subcategories" class="col col-sm-6 wow tpfadeUp" data-wow-duration=".9s"
//         data-wow-delay=".3s"
//         style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.3s; animation-name: tpfadeUp;">
//         <div [class.active]="activeSubcategory === subcategory" (click)="setActiveSubcategory(subcategory)"
//           class="tp-feature-item mb-30 text-center">
//           <div class="tp-feature-icon">
//             <i [class]="subcategory.subcategory_icon"></i>
//           </div>
//           <div class="tp-feature-content">
//             <h4 class="tp-feature-title-sm">{{ subcategory.subcategory_name }}</h4>
//             <p class="tp-feature-title-sm">{{ subcategory.subcategory_description }}</p>
//           </div>
//         </div>
//       </div>
//     </div>

//     <!-- <div class="row justify-content-center">
//       <div *ngFor="let subcategory of subcategories" class="col-xs-12 col-sm-6 col-md-3">
//         <div class="service-item" [class.active]="activeSubcategory === subcategory"
//           (click)="setActiveSubcategory(subcategory)">
//           <div class="service-icon">
//             <i [class]="subcategory.subcategory_icon"></i>
//           </div>
//           <div class="service-content">
//             <h6>{{ subcategory.subcategory_name }}</h6>
//             <p>{{ subcategory.subcategory_description }}</p>
//           </div>
//         </div>
//       </div>
//     </div> -->

//   </div>
// </div>

// <!-- Active Subcategory Content -->
// <div id="feature-one-page" class="tp-feature-area pb-110 p-relative z-index grey-bg-2"
//   *ngIf="shouldShowCourseSection()">
//   <div class="container">
//     <div class="row justify-content-center">
//       <div class="col-md-12">
//         <div class="service-details p-0" id="skillDevelopmentDetails">
//           <div class="service-content">
//             <div class="row">
//               <div class="col-md-6">
//                 <img src="assets/images/blog49-1.jpg" alt="">
//               </div>
//               <div class="col-md-6">
//                 <div class="p-4">
//                   <h6>{{ activeSubcategory.subcategory_name }}</h6>
//                   <p>{{ activeSubcategory.subcategory_description }}</p>

//                   <!-- Conditionally display message if no items are available -->
//                   <div *ngIf="listItems.length === 0" class="alert alert-info">
//                     No items available to select
//                   </div>

//                   <ul class="text-start" *ngIf="listItems.length > 0">
//                     <li class="form-group" *ngFor="let item of listItems">
//                       <input type="checkbox" id="item_{{ item.listitem_id }}" name="item" [(ngModel)]="item.selected"
//                         [value]="item.listitem_id" [disabled]="item.disabled">&nbsp;
//                       <label for="item_{{ item.listitem_id }}"> {{ item.list_item }} </label>
//                     </li>
//                   </ul>
//                   <button class="save-button" (click)="saveSelectedItems()">Save Selected Items</button>

//                   <!-- <div *ngIf="errorMessage" class="alert alert-danger">
//                     {{ errorMessage }}
//                   </div>
//                   <div *ngIf="successMessage" class="alert alert-success">
//                     {{ successMessage }}
//                   </div> -->

//                   <!-- Success Message -->
//                   <!-- <div *ngIf="successMessage" class="alert alert-success">
//                   {{ successMessage }}
//                 </div> -->

//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>

//   <!-- Error Message Modal -->
//   <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
//     <div class="modal-dialog">
//       <div class="modal-content">
//         <div class="modal-header">
//           <h5 class="modal-title" id="errorModalLabel">Error</h5>
//           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//         </div>
//         <div class="modal-body">
//           {{ errorMessage }}
//         </div>
//         <div class="modal-footer">
//           <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//         </div>
//       </div>
//     </div>
//   </div>

//   <!-- Success Message Modal -->
//   <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
//     <div class="modal-dialog">
//       <div class="modal-content">
//         <div class="modal-header">
//           <h5 class="modal-title" id="successModalLabel">Success</h5>
//           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//         </div>
//         <div class="modal-body">
//           {{ successMessage }}
//         </div>
//         <div class="modal-footer">
//           <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//         </div>
//       </div>
//     </div>
//   </div>

// </div>














// <!-- Courses -->
// <div id="feature-one-page" class="tp-feature-area pt-130 pb-110 p-relative z-index grey-bg-2"
//   *ngIf="activeSubcategory && activeSubcategory.service_content === 'active'">
//   <div class="tp-feature-shape-1 d-none d-xxl-block">
//     <img src="assets/images/ab-shape-2.png" alt="">
//   </div>
//   <div class="tp-feature-shape-2">
//     <img src="assets/images/ab-bg.png" alt="">
//   </div>
//   <div class="container">

//     <div class="row row-cols-xl-4 row-cols-lg-2 justify-content-center">
//       <div *ngFor="let subcategory of subcategories" class="col col-sm-6 wow tpfadeUp" data-wow-duration=".9s"
//         data-wow-delay=".3s"
//         style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.3s; animation-name: tpfadeUp;">
//         <div [class.active]="activeSubcategory === subcategory" (click)="setActiveSubcategory(subcategory)"
//           class="tp-feature-item mb-30 text-center">
//           <div class="tp-feature-icon">
//             <i [class]="subcategory.subcategory_icon"></i>
//           </div>
//           <div class="tp-feature-content">
//             <h4 class="tp-feature-title-sm">{{ subcategory.subcategory_name }}</h4>
//             <p class="tp-feature-title-sm">{{ subcategory.subcategory_description }}</p>
//           </div>
//         </div>
//       </div>
//     </div>

//     <!-- <div class="row justify-content-center">
//       <div *ngFor="let subcategory of subcategories" class="col-xs-12 col-sm-6 col-md-3">
//         <div class="service-item" [class.active]="activeSubcategory === subcategory"
//           (click)="setActiveSubcategory(subcategory)">
//           <div class="service-icon">
//             <i [class]="subcategory.subcategory_icon"></i>
//           </div>
//           <div class="service-content">
//             <h6>{{ subcategory.subcategory_name }}</h6>
//             <p>{{ subcategory.subcategory_description }}</p>
//           </div>
//         </div>
//       </div>
//     </div> -->

//   </div>
// </div>

// <!-- Active Subcategory Content -->
// <div id="feature-one-page" class="tp-feature-area pb-110 p-relative z-index grey-bg-2"
//   *ngIf="shouldShowCourseSection()">
//   <div class="container">
//     <div class="row justify-content-center">
//       <div class="col-md-12">
//         <div class="service-details p-0" id="skillDevelopmentDetails">
//           <div class="service-content">
//             <div class="row">
//               <div class="col-md-6">
//                 <img src="assets/images/blog49-1.jpg" alt="">
//               </div>
//               <div class="col-md-6">
//                 <div class="p-4">
//                   <h6>{{ activeSubcategory.subcategory_name }}</h6>
//                   <p>{{ activeSubcategory.subcategory_description }}</p>

//                   <!-- Conditionally display message if no items are available -->
//                   <div *ngIf="listItems.length === 0" class="alert alert-info">
//                     No items available to select
//                   </div>

//                   <ul class="text-start" *ngIf="listItems.length > 0">
//                     <li class="form-group" *ngFor="let item of listItems">
//                       <input type="checkbox" id="item_{{ item.listitem_id }}" name="item" [(ngModel)]="item.selected"
//                         [value]="item.listitem_id" [disabled]="item.disabled">&nbsp;
//                       <label for="item_{{ item.listitem_id }}"> {{ item.list_item }} </label>
//                     </li>
//                   </ul>
//                   <button class="save-button" (click)="saveSelectedItems()">Save Selected Items</button>

//                   <!-- <div *ngIf="errorMessage" class="alert alert-danger">
//                     {{ errorMessage }}
//                   </div>
//                   <div *ngIf="successMessage" class="alert alert-success">
//                     {{ successMessage }}
//                   </div> -->

//                   <!-- Success Message -->
//                   <!-- <div *ngIf="successMessage" class="alert alert-success">
//                   {{ successMessage }}
//                 </div> -->

//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>

//   <!-- Error Message Modal -->
//   <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
//     <div class="modal-dialog">
//       <div class="modal-content">
//         <div class="modal-header">
//           <h5 class="modal-title" id="errorModalLabel">Error</h5>
//           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//         </div>
//         <div class="modal-body">
//           {{ errorMessage }}
//         </div>
//         <div class="modal-footer">
//           <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//         </div>
//       </div>
//     </div>
//   </div>

//   <!-- Success Message Modal -->
//   <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
//     <div class="modal-dialog">
//       <div class="modal-content">
//         <div class="modal-header">
//           <h5 class="modal-title" id="successModalLabel">Success</h5>
//           <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//         </div>
//         <div class="modal-body">
//           {{ successMessage }}
//         </div>
//         <div class="modal-footer">
//           <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//         </div>
//       </div>
//     </div>
//   </div>

// </div>













// <div id="feature-one-page" class="tp-feature-area pb-110 p-relative z-index grey-bg-2"
// *ngIf="shouldShowCourseSection()">
// <div class="container">
//   <div class="row justify-content-center">
//     <div class="col-md-12">
//       <div class="service-details p-0" id="skillDevelopmentDetails">
//         <div class="service-content">
//           <div class="row">
//             <div class="col-md-6">
//               <img src="assets/images/blog49-1.jpg" alt="">
//             </div>
//             <div class="col-md-6">
//               <div class="p-4">
//                 <h6>{{ activeSubcategory.subcategory_name }}</h6>
//                 <p>{{ activeSubcategory.subcategory_description }}</p>

//                 <!-- Conditionally display message if no items are available -->
//                 <div *ngIf="listItems.length === 0" class="alert alert-info">
//                   No items available to select
//                 </div>

//                 <ul class="text-start" *ngIf="listItems.length > 0">
//                   <li class="form-group" *ngFor="let item of listItems">
//                     <input type="checkbox" id="item_{{ item.listitem_id }}" name="item" [(ngModel)]="item.selected"
//                       [value]="item.listitem_id" [disabled]="item.disabled">&nbsp;
//                     <label for="item_{{ item.listitem_id }}"> {{ item.list_item }} </label>
//                   </li>
//                 </ul>
//                 <button class="save-button" (click)="saveSelectedItems()">Save Selected Items</button>

//                 <!-- <div *ngIf="errorMessage" class="alert alert-danger">
//               {{ errorMessage }}
//             </div>
//             <div *ngIf="successMessage" class="alert alert-success">
//               {{ successMessage }}
//             </div> -->

//                 <!-- Success Message -->
//                 <!-- <div *ngIf="successMessage" class="alert alert-success">
//             {{ successMessage }}
//           </div> -->

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

// <!-- Error Message Modal -->
// <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
//   <div class="modal-dialog">
//     <div class="modal-content">
//       <div class="modal-header">
//         <h5 class="modal-title" id="errorModalLabel">Error</h5>
//         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//       </div>
//       <div class="modal-body">
//         {{ errorMessage }}
//       </div>
//       <div class="modal-footer">
//         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//       </div>
//     </div>
//   </div>
// </div>

// <!-- Success Message Modal -->
// <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
//   <div class="modal-dialog">
//     <div class="modal-content">
//       <div class="modal-header">
//         <h5 class="modal-title" id="successModalLabel">Success</h5>
//         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//       </div>
//       <div class="modal-body">
//         {{ successMessage }}
//       </div>
//       <div class="modal-footer">
//         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//       </div>
//     </div>
//   </div>
// </div>

// </div>