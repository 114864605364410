import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
// import { AuthService } from './auth.service'; // Import the AuthService
import { Location } from '@angular/common'
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthManagerService {

  authcode: any;
  bearerToken: any;
  private clientId = environment.clientId;

  private apiUrl = '/Mobileapi/send_whatsapp_otp';
  private valUrl = '/Mobileapi/validate_otp';
  private apitoken = '609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW';
  private fetchTokenUrl = '/Mobileapi/fetch_bearer_token';
  private updateauthandtokenUrl = '/Mobileapi/giftvoucher_authorization_and_token';

  private authApi = '/Mobileapi/giftvoucher_authorization_verify';
  private bearerTokenApi = '/Mobileapi/giftvoucher_get_bearer_token';

  constructor(private http: HttpClient, private router: Router) { }


  checkToken(mobile_no: string): void {
    const storedMobileNo = localStorage.getItem('microsite');
    if (storedMobileNo === mobile_no) {
      this.router.navigate(['/index']);
    } else {
      this.sendOtp(mobile_no, this.apitoken);
    }
  }

  sendOtp(mobile_no: string, token: string): void {
    this.http.post(this.apiUrl, { mobile_no: mobile_no, token: token })
      .subscribe(
        (response: any) => {
          if (response.status) {
            alert('Otp sent to WhatsApp');
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  async handleOtpSubmission(mobile_no: string, otp: string): Promise<void> {
    this.validateOtp(mobile_no, otp, this.apitoken).subscribe(
      async (response: any) => {
        if (response.response.status) {
          // console.log('OTP validation successful:', response);
          localStorage.setItem('microsite', mobile_no);
          localStorage.setItem('profileData', JSON.stringify(response.response.data));

          this.router.navigate(['/index']);

          try {
            const tokenResponse: any = await this.fetchToken().toPromise();
            if (tokenResponse.response.status) {
              const bearerToken = tokenResponse.response.data.bearerToken;
              localStorage.setItem('bearerToken', bearerToken);
              // console.log('Bearer token fetched and stored:', bearerToken);
            } else {
              console.error('Error fetching vouchers.Please try again', tokenResponse.response.msg);
              alert('Failed to fetch bearer token.');
            }
          } catch (error) {
            console.error('Error fetching bearer token:', error);
            alert('Error fetching vouchers. Please try again.');
          }
        } else {
          if (response.response.msg === 'Sorry, you are not authorized to login to the site. Please contact your Relationship Manager for further details.') {
            alert(response.response.msg);
          } else {
            alert('OTP Verification failed. Please try again');
          }
        }
      },
      error => {
        console.error('Error validating OTP:', error);
        alert('OTP Verification failed. Please try again.');
      }
    );
  }

  validateOtp(mobile_no: string, otp: string, token: string) {
    return this.http.post(this.valUrl, { mobile_no: mobile_no, otp: otp, token: token });
  }

  // fetch the bearer token from the database
  fetchToken() {
    const fetchTokenData = { clientId: this.clientId };
    console.log(fetchTokenData);

    return this.http.post(this.fetchTokenUrl, fetchTokenData);
  }

  async updateBearerToken(): Promise<void> {
    const fetchTokenData = {
      token: this.apitoken,
      clientId: environment.clientId,
      username: environment.username,
      password: environment.password,
      clientSecret: environment.clientSecret,
    };

    try {
      const response = await this.http.post<any>(this.updateauthandtokenUrl, fetchTokenData, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).toPromise();

      // console.log('Token response:', response);

      if (response && response.response && response.response.data && response.response.data.token) {
        localStorage.setItem('bearerToken', response.response.data.token);
        // console.log('Bearer token stored:', response.response.data.token);
    } else {
        console.error('No bearer token received:', response);
        throw new Error('No bearer token received');
    }
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error; // Propagate the error
    }
  }



  //-----------------authorization_code and bearer_token--------------------------------

  // async fetchBearerToken(): Promise<void> {
  //   try {
  //     const authorizationCode = await this.generateAuthCode();
  //     const payloads = {
  //       token: this.apitoken,
  //       clientId: '5e0417a9a61fbebbf22efb5c2646bee1',
  //       clientSecret: '7d180a544d33745e18c10811ab975ee7',
  //       authorizationCode: authorizationCode
  //     };

  //     const response: any = await this.http.post(this.bearerTokenApi, payloads).toPromise();

  //     if (!response?.response?.data) {
  //       console.error('Response format is invalid:', response);
  //       throw new Error('Invalid response format');
  //     }

  //     const dataString = response.response.data;

  //     let dataObject;
  //     try {
  //       dataObject = JSON.parse(dataString);
  //     } catch (parseError) {
  //       console.error('Error parsing response data:', parseError);
  //       throw new Error('Error parsing response data');
  //     }

  //     const bearerToken = dataObject.token;

  //     if (!bearerToken) {
  //       console.error('Bearer token not found in response data:', dataObject);
  //       throw new Error('Bearer token not found');
  //     }

  //     localStorage.setItem('bearerToken', bearerToken);
  //     console.log('Bearer token fetched and stored:', bearerToken);
  //   } catch (error) {
  //     console.error('Error fetching bearer token:', error);
  //     this.handleTokenRejected();
  //     throw error;
  //   }
  // }

  async fetchBearerToken(): Promise<void> {
    try {
      const authorizationCode = await this.generateAuthCode();
      const payloads = {
        token: this.apitoken,
        clientId: '5e0417a9a61fbebbf22efb5c2646bee1',
        clientSecret: '7d180a544d33745e18c10811ab975ee7',
        authorizationCode: authorizationCode
      };

      const response: any = await this.http.post(this.bearerTokenApi, payloads).toPromise();

      console.log('API Response:', response);

      if (response.status !== 1 || !response.data) {
        console.error('Response format is invalid or an error occurred:', response);
        throw new Error('Invalid response format or error occurred');
      }

      const dataString = response.data;

      if (dataString.startsWith('<!DOCTYPE')) {
        console.error('Received HTML response instead of JSON:', dataString);
        throw new Error('Received HTML response instead of JSON');
      }

      let dataObject;
      try {
        dataObject = JSON.parse(dataString);
      } catch (parseError) {
        console.error('Error parsing response data:', parseError);
        throw new Error('Error parsing response data');
      }

      const bearerToken = dataObject.token;

      if (!bearerToken) {
        console.error('Bearer token not found in response data:', dataObject);
        throw new Error('Bearer token not found');
      }

      localStorage.setItem('bearerToken', bearerToken);
      console.log('Bearer token fetched and stored:', bearerToken);
    } catch (error) {
      console.error('Error fetching bearer token:', error);
      // this.handleTokenRejected();
      // throw error;
    }
  }


  async ensureBearerToken(): Promise<string> {
    let bearerToken = localStorage.getItem('bearerToken');

    if (!bearerToken) {
      await this.fetchBearerToken();
      bearerToken = localStorage.getItem('bearerToken');
    }

    if (!bearerToken) {
      throw new Error('Bearer token could not be fetched or found');
    }

    return bearerToken;
  }

  handleTokenRejected(): void {
    localStorage.removeItem('microsite');
    localStorage.removeItem('profileData');
    localStorage.removeItem('bearerToken');
    this.router.navigate(['/otp']);
  }

  async generateAuthCode(): Promise<string> {
    const payloads = {
      token: this.apitoken,
      clientId: '5e0417a9a61fbebbf22efb5c2646bee1',
      username: 'centuryplysandbox@woohoo.in',
      password: 'WoohooUAT@1'
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    try {
      const response: any = await this.http.post(this.authApi, payloads, { headers }).toPromise();
      const dataString = response.response.data;
      const dataObject = JSON.parse(dataString);
      const authorizationCode = dataObject.authorizationCode;
      return authorizationCode;
    } catch (error) {
      console.error('Error fetching authorization code:', error);
      throw error;
    }
  }


  //---------logout---------
  logout(): void {
    // Clear local storage
    localStorage.removeItem('microsite');
    localStorage.removeItem('profileData');
    localStorage.removeItem('bearerToken');

    // Redirect to OTP submission page
    this.router.navigate(['/otp']);
  }


}