import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private bearerTokenKey = 'bearerToken';

  setBearerToken(token: string): void {
    localStorage.setItem(this.bearerTokenKey, token);
  }

  getBearerToken(): string | null {
    return localStorage.getItem(this.bearerTokenKey);
  }

}
