<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"> -->


<main>


  <!-- Banner -->
  <div class="breadcrumb__area breadcrumb__overlay breadcrumb__height p-relative fix"
    data-background="assets/img/breadcurmb/breadcurmb.jpg"
    style="background-image: url(assets/images/breadcurmb/breadcurmb.jpg);">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12">
          <div class="breadcrumb__content z-index text-center">
            <h3 class="breadcrumb__title">Profile</h3>
            <div class="breadcrumb__list">
              <span><a class="no-underline" routerLink="/index">Home</a></span>
              <span class="dvdr"><i class="fa fa-angle-right"></i></span>
              <span>Profile</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="container mt-5">
    <!-- Categories Section -->
    <div class="row row-cols-xl-5 row-cols-lg-3 justify-content-center">
      <div *ngFor="let category of categories" class="col col-sm-6 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".3s"
        style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.3s; animation-name: tpfadeUp;">
        <a href="javascript:void(0);" (click)="onCategoryClick(category.id)" [class.active]="selectedIndex === category.id">
          <div class="tp-feature-item mb-10 text-center">
            <!--div class="tp-feature-icon">
              <i [class]="category.category_icon"></i>
            </div-->
            <div class="tp-feature-content">
              <h4 class="tp-feature-title-sm">{{ category.category_name }}</h4>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- Table for Edited Data -->
    <div *ngIf="showTable" class="row justify-content-center">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="service-details p-0">
          <div class="service-content">
            <div class="row">
              <div class="col-md-12">
                <div class="p-4">
                  <h6>Your Selected: {{ selectedCategoryName }}</h6>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>List Items</th>
                        <th>Selected Date</th>
                        <th>Status</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="tableData.length === 0">
                        <td colspan="5" class="text-center">No data available</td>
                      </tr>
                      <tr *ngFor="let item of tableData">
                        <td>{{ item.subcategory_name }}</td>
                        <td>{{ item.list_item }}</td>
                        <!-- <td>{{ item.selected_at }}</td> -->
                        <td>{{ item.selected_at | date:'MMMM d, y, h:mm:ss a' }}</td>
                        <td>{{ item.status }}</td>
                        <td>
                          <button (click)="deleteItem(item.listitem_id, item.token)" >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Table for Voucher Items -->
    <div *ngIf="showVoucherTable" class="row justify-content-center">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="service-details p-0">
          <div class="service-content">
            <div class="row">
              <div class="col-md-12">
                <div class="p-4">
                  <h6>Voucher Items</h6>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Voucher Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="voucherData.length === 0">
                        <td colspan="4" class="text-center">No data available</td>
                      </tr>

                      <ng-container *ngFor="let item of voucherData">
                        <tr>
                          <td>{{ item.orderId }}</td>
                          <td>{{ item.productNames }}</td>
                          <td>{{ item.status }}</td>
                          <td>
                            <button (click)="viewDetails(item)">View</button> <!-- View button -->
                          </td>
                        </tr>
                      </ng-container>

                      <!-- <ng-container *ngFor="let item of voucherData">
                        <tr *ngIf="!(item.response_data?.code === 401 || item.response_data?.message?.includes('Failed'))">
                          <td>{{ item.response_data?.data.orderId || item.response_data?.orderId }}</td>
                          <td *ngFor="let card of (item.response_data?.data.cards?.length ? item.response_data?.data.cards : item.response_data?.cards)">
                            {{ card.productName }}
                          </td>
                          <td>{{ item.response_data?.data.status || item.response_data?.status }}</td>
                        </tr>
                      </ng-container> -->
                      
                    </tbody>
                  </table>


                  <!-- Modal for displaying additional card details -->
                  <div *ngIf="selectedCards.length > 0" class="modal">
                    <h3>Card Details</h3>
                    <div *ngFor="let card of selectedCards">
                      <p *ngIf="card.cardNumber">Card Number: {{ card.cardNumber }}</p>
                      <p *ngIf="card.cardPin">Card Pin: {{ card.cardPin }}</p>
                      <p *ngIf="card.amount">Amount: {{ card.amount }}</p>
                      <p *ngIf="card.validity">Validity: {{ card.validity | date:'d/M/yyyy' }}</p>
                    </div>
                    <button (click)="closeDetails()">Close</button>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<!-- <tr *ngFor="let item of voucherData">
  <td>{{ item.response_data?.data.orderId }}</td>
  <td>{{ item.response_data?.orderId }}</td>
  <td>{{ item.product_details?.name }}</td>
  <td>{{ item.response_data?.data.status }}</td>
  <td>{{ item.response_data?.status }}</td>
  
</tr> -->