import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { AuthManagerService } from './auth-manager.service'; // Import the AuthManagerService
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CenturyPly';

  showHeaderFooter = true;

  constructor(private authManager: AuthManagerService, private route: ActivatedRoute, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current URL includes '/otp'
        this.showHeaderFooter = !this.router.url.includes('/otp');
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const phoneNumber = params['phone'];
      if (phoneNumber) {
        this.authManager.checkToken(phoneNumber);
      }
    });
  }
  // constructor(private router: Router) {
  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationEnd) {
  //       // Check if the current URL includes '/otp'
  //       this.showHeaderFooter = !this.router.url.includes('/otp');
  //     }
  //   });
  // }

}
