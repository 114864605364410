import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeQuotes'
})
export class RemoveQuotesPipe implements PipeTransform {

  transform(value: string): string {
    if (typeof value === 'string') {
      return value.replace(/^"(.*)"$/, '$1');
    }
    return value;
  }

}
