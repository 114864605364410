
    <!-- back to top start -->
    <div class="back-to-top-wrapper">
        <button id="back_to_top" type="button" class="back-to-top-btn">
            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 6L6 1L1 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </button>
    </div>
    <!-- back to top end -->

    <header class="tp-header-height">

        <!-- header area end -->
        <div id="header-sticky" class="tp-header-area tp-header-3-customize tp-header-3-mobile-space">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 d-flex justify-content-center">
                        <div class="tp-header-logo">
                            <a href="index.html"><img src="assets/images/logo.png" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- header area end -->

    </header>

